import Statistics from "../components/Statistics";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import { useEffect, useState } from "react";
import BackToTopButton from "../components/BackToTopButton";
import AppHeader from "../components/AppHeader";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
const Pricing = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [counterOn, setCounterOn] = useState(false);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000); // Simulate a 2-second loading delay
      return () => clearTimeout(timer);
    }, []);
    return ( 
    <>
    <div>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <BackToTopButton />
          <div className="search-area">
            <div className="search-inner p-relative">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="search-wrapper">
                      <div className="search-close">
                        <button className="search-close-btn">
                          <svg
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 1L1 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M1 1L11 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="search-content pt-35">
                        <h3 className="heading text-center mb-30">
                          Hi! How can we help You?
                        </h3>
                        <div className="d-flex justify-content-center px-5">
                          <div className="search w-100 p-relative">
                            <input
                              type="text"
                              className="search-input"
                              placeholder="Search..."
                            />
                            <button className="search-icon">
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-overlay" />
          <div className="offcanvas__area">
            <div className="offcanvas__wrapper">
              <div className="offcanvas__close">
                <button className="offcanvas__close-btn offcanvas-close-btn">
                  <svg
                    width={12}
                    height={12}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1L1 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1L11 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="offcanvas__content">
                <div className="offcanvas__top mb-50 d-flex justify-content-between align-items-center">
                  <div className="offcanvas__logo logo">
                    <a href="/">
                      <img src="assets/img/logo/logo-black.png" alt="logo" />
                    </a>
                  </div>
                </div>
                <div className="tp-main-menu-mobile fix d-xl-none mb-40" />
                <div className="offcanvas__contact">
                  <h4 className="offcanvas__title">Contacts</h4>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-sharp fa-solid fa-location-dot" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="https://www.google.com/maps/search/86+Road+Broklyn+Street,+600+New+York,+USA/@40.6897806,-74.0278086,12z/data=!3m1!4b1">
                        86 Road Broklyn Street, 600{" "}
                      </a>
                    </div>
                  </div>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-solid fa-envelope" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="https://template.wphix.com/cdn-cgi/l/email-protection#bfd1dadadbd7dad3cfffdcd0d2cfded1c691dcd0d2">
                        {" "}
                        
                        info@irembofinance.com
                      </a>
                    </div>
                  </div>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-solid fa-phone" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="tel:+256 (0) 772 415 497">
                        {" "}
                        +256 (0) 772 415 497
                      </a>
                    </div>
                  </div>
                </div>
                <div className="offcanvas__social">
                  <a className="icon facebook" href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a className="icon twitter" href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a className="icon youtube" href="#">
                    <i className="fab fa-youtube" />
                  </a>
                  <a className="icon linkedin" href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="body-overlay" />
          {/* ...............................................
          HEADER
          ............................................... */}
          <AppHeader />
          <main>


          <section className="tp-hero-area-4 pt-30 pb-30  p-relative" data-bg-color="#16243E" style={{backgroundColor: 'rgb(22, 36, 62)'}}>
            <div className="breadcrumb__bg" data-background="assets/img/breadcrumb/bg.png" style={{backgroundImage: 'url("assets/img/breadcrumb/bg.png")'}} />
            <div className="container">
                <div className="row align-items-center">
                <div className="col-sm-6">
                    <div className="breadcrumb__content">
                    <h3 className="breadcrumb__title">Pricing</h3>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="breadcrumb__content">
                    <div className="breadcrumb__list text-center text-sm-end">
                        <span><Link to="/home">Home</Link></span>
                        <span className="dvdr"><i className="fa-regular fa-angle-right" /></span>
                        <span>Pricing</span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>

            
            <section
              className="tp-service-area-3 pt-60 pb-60"
              data-bg-color="#F6F6F9"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tp-service-title-wrapper-3 mb-40">
                      <span className="text-center tp-section-title-pre">Pricing Plan</span>

                      <p> The pricing structure for the Financial Management Information System (FMIS) has been thoughtfully designed to ensure accessibility and affordability for SACCOs of all sizes. 
          We understand the importance of keeping costs low while delivering high value, and thus, our pricing model reflects this commitment. 
          The FMIS offers a flexible and scalable pricing plan that aligns with the specific needs and budgetary constraints of each SACCO. 
          Our transparent pricing approach eliminates any hidden fees or unexpected expenses, providing SACCOs with a clear understanding of their investment. 
          Whether you are a small, medium, or large SACCO, the FMIS offers competitive pricing options that cater to your unique requirements. 
          By adopting the FMIS, SACCOs can leverage advanced financial management tools and optimize their operations without straining their financial resources. 
          We believe that technology-driven financial solutions should be accessible to all, and our pricing structure embodies this belief, empowering SACCOs to enhance their efficiency and effectiveness without compromising their financial stability.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <h1 className="h6 text-uppercase font-weight-bold  text-center">Essential Plan</h1>
                    <h5 className="text-success font-weight-bold">From <span className="text-small font-weight-normal ml-2">$500</span></h5>
                    <input type="hidden" defaultValue="standard" id="plan" />
                    <input type="hidden" defaultValue={500} id="price" />
                    <ul className="list-unstyled text-small text-left" style={{textAlign: 'left', marginTop: '0!important'}}>
                        <hr/>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Cloud based</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Member management </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Savings management </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Loan Management</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Shares Management</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Daily transactions reports</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />30 - 1,000 Members </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Automatic SMS Alerts</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Up to 5 Financial Reports</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />USSD Banking</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Credit Reference Bureau</li>
                        <li className="mb-3 text-muted"> <i style={{color: '#ff5630'}} className="fa fa-x mr-2" /> <del>Multi branch management</del> </li>
                        <li className="mb-3 text-muted"> <i style={{color: '#ff5630'}} className="fa fa-x mr-2" /> <del>Agent Mobile App</del> </li>
                        <li className="mb-3 text-muted"> <i style={{color: '#ff5630'}} className="fa fa-x mr-2" /> <del>Customer Mobile App</del> </li>
                        <li className="mb-3 text-muted"> <i style={{color: '#ff5630'}} className="fa fa-x mr-2" /> <del>Agency Banking</del> </li>
                        <li className="mb-3 text-muted"> <i style={{color: '#ff5630'}} className="fa fa-x mr-2" /> <del>100% Customisation</del></li>
                    </ul>
                    <a href="#" className="mt-55 btn btn-success btn-block p-2 shadow ">Purchase</a>
                    </div>

                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <h1 className="h6 text-uppercase font-weight-bold text-center">Advanced Plan</h1>
                    <h5 className="text-success font-weight-bold">From <span className="text-small font-weight-normal ml-2">$750</span></h5>
                    <div className="custom-separator  mx-auto bg-warning " style={{marginTop: '0!important', marginBottom: '1rem!important'}} />
                    <ul className="list-unstyled text-small text-left" style={{textAlign: 'left', marginTop: '0!important'}}>
                        <hr/>
                        <input type="hidden" defaultValue="Premium" id="plan2" />
                        <input type="hidden" defaultValue={1000} id="price2" />
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Cloud based</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Member management </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Savings management </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Loan Management</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Shares Management</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Daily transactions reports</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />1,000 - 2500 Members </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Automatic SMS Alerts</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Up to 10 Financial Reports</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />USSD Banking</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Multi branch management</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Agent Mobile App</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Credit Reference Bureau</li>
                        <li className="mb-3 text-muted"> <i style={{color: '#ff5630'}} className="fa fa-x mr-2" /> <del>Customer Mobile App</del> </li>
                        <li className="mb-3 text-muted"> <i style={{color: '#ff5630'}} className="fa fa-x mr-2" /> <del>Agency Banking</del> </li>
                        <li className="mb-3 text-muted"> <i style={{color: '#ff5630'}} className="fa fa-x mr-2" /> <del>100% Customisation</del></li>
                    </ul>
                    <a href="#" className="mt-55 btn btn-success btn-block p-2 ">Purchase</a>
                    </div>

                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <h1 className="h6 text-uppercase font-weight-bold mb-2 text-center">Premium Plan</h1>
                    <h5 className="text-success font-weight-bold">From <span className="text-small font-weight-normal ml-2">$1000</span></h5>
                    <div className="custom-separator my-4 mx-auto bg-warning " style={{marginTop: '0!important', marginBottom: '1rem!important'}} />
                    <ul className="list-unstyled  text-small text-left" style={{textAlign: 'left', marginTop: '0!important'}}>
                    <hr/>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Cloud based</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Member management </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Savings management </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Loan Management</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Shares Management</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Daily transactions reports</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />2,500 - 10,000 Members </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Automatic SMS Alerts</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Up to 15 Financial Reports</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />USSD Banking</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Multi branch management</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Credit Reference Bureau</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Agent Mobile App</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Customer Mobile App</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Agency Banking</li>
                        <li className="mb-3 text-muted"> <i style={{color: '#ff5630'}} className="fa fa-x mr-2" /> <del>100% Customisation</del></li>
                    </ul>
                    <br/>
                    <a href="#" className=" btn btn-success btn-block  shadow">Purchase</a>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <h1 className="h6 text-uppercase font-weight-bold mb-2 text-center">Enterprise Plan</h1>
                    <h5 className="text-danger font-weight-bold">Custom Pricing </h5>
                    <div className="font-weight-bold ">Please call us</div>
                    {/* <div className="font-weight-bold " style={{marginTop: '0!important', marginBottom: '1rem!important', fontSize: '16px!important'}}>Please call us</div> */}
                    <ul className="list-unstyled  text-small text-left" style={{textAlign: 'left', marginTop: '0!important', marginBottom: '1.5rem!important'}}>
                        <hr/>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Cloud based</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Member management </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Savings management </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Loan Management</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Shares Management</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Daily transactions reports</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />10,000+ Members </li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Automatic SMS Alerts</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Up to 23 Financial Reports</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />USSD Banking</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Multi branch management</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Agent Mobile App</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Credit Reference Bureau</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Customer Mobile App</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />Agency Banking</li>
                        <li className="mb-3"> <i className="fa fa-check mr-2 text-success" />100% Customisation</li>
                    </ul>
                    <button className="mt-20 btn btn-block p-2 btn-danger"><i className="bi bi-telephone-fill" /> 256 (0) 772 415 497</button>
                    </div>


                  </div>
                </div>
              </div>
            </section>
            
          </main>
          <Footer />
        </div>
      )}
    </div>
    </> 
    );
}
 
export default Pricing;