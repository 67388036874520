import { Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import About from "./pages/About";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Pricing from "./pages/Pricing";
import Portfolio from "./pages/Portfolio";
import Policy from "./pages/Policy";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" index element={<Index />} />
        <Route path="home" element={<Index />} />
        <Route path="about-us" element={<About />} />
        <Route path="our-team" element={<Team />} />
        <Route path="contact" element={<Contact />} />
        <Route path="payment-plan" element={<Pricing />} />
        <Route path="clients" element={<Portfolio />} />
        <Route path="privacy-policy" element={<Policy />} />
      </Routes>
    </div>
  );
}

export default App;
