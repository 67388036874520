import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { useState } from "react";
const Statistics = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <>
      <section
        className=" tp-counter-area-2 p-relative pt-120 pb-60"
        // data-bg-color="#16243E"
        // style={{
        //   backgroundColor: "white",
        // }}
      >
        <div className="tp-counter-bg-shape-2">
          <img src="assets/img/counter/home-3/bg-shape.png" alt="stat" />
        </div>
        <div className=" container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="tp-counter-item-2 text-center mb-30">
                <div className="tp-counter-item-2-icon">
                  <span>
                    <img
                      src="assets/img/counter/home-3/members-served.png"
                      alt="stat"
                    />
                  </span>
                </div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <h4 className="tp-counter-title-2">
                    {counterOn && (
                      <CountUp start={0} end={10127} duration={2} delay={0} />
                    )}
                    +
                  </h4>
                </ScrollTrigger>
                <p>Members Served</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="tp-counter-item-2 text-center mb-30">
                <div className="tp-counter-item-2-icon">
                  <span>
                    {/* <img src="assets/img/counter/home-3/icon-2.svg" alt /> */}
                    <img
                      src="assets/img/counter/home-3/transactions.png"
                      alt="stat"
                    />
                  </span>
                </div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <h4 className="tp-counter-title-2">
                    {counterOn && (
                      <CountUp start={0} end={1000000} duration={2} delay={0} />
                    )}
                    +
                  </h4>
                </ScrollTrigger>
                <p>Transactions Processed</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="tp-counter-item-2 text-center mb-30">
                <div className="tp-counter-item-2-icon">
                  <span>
                    <img
                      src="assets/img/counter/home-3/years.png"
                      alt="stat"
                    />
                  </span>
                </div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <h4 className="tp-counter-title-2">
                    {counterOn && (
                      <CountUp start={0} end={11} duration={2} delay={0} />
                    )}
                  </h4>
                </ScrollTrigger>
                <p>Years Of Experience</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="tp-counter-item-2 text-center mb-30">
                <div className="tp-counter-item-2-icon">
                  <span>
                    <img
                      src="assets/img/counter/home-3/migration.png"
                      alt="stat"
                    />
                  </span>
                </div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <h4 className="tp-counter-title-2">
                    {counterOn && (
                      <CountUp start={0} end={5} duration={2} delay={0} />
                    )}
                    +
                  </h4>
                </ScrollTrigger>
                <p>Districts Reached</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Statistics;
