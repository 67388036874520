import Statistics from "../components/Statistics";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import { useEffect, useState } from "react";
import BackToTopButton from "../components/BackToTopButton";
import AppHeader from "../components/AppHeader";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
const Contact = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [counterOn, setCounterOn] = useState(false);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000); // Simulate a 2-second loading delay
      return () => clearTimeout(timer);
    }, []);
    return ( 
    <>
    <div>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <BackToTopButton />
          <div className="search-area">
            <div className="search-inner p-relative">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="search-wrapper">
                      <div className="search-close">
                        <button className="search-close-btn">
                          <svg
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 1L1 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M1 1L11 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="search-content pt-35">
                        <h3 className="heading text-center mb-30">
                          Hi! How can we help You?
                        </h3>
                        <div className="d-flex justify-content-center px-5">
                          <div className="search w-100 p-relative">
                            <input
                              type="text"
                              className="search-input"
                              placeholder="Search..."
                            />
                            <button className="search-icon">
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-overlay" />
          <div className="offcanvas__area">
            <div className="offcanvas__wrapper">
              <div className="offcanvas__close">
                <button className="offcanvas__close-btn offcanvas-close-btn">
                  <svg
                    width={12}
                    height={12}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1L1 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1L11 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="offcanvas__content">
                <div className="offcanvas__top mb-50 d-flex justify-content-between align-items-center">
                  <div className="offcanvas__logo logo">
                    <a href="/">
                      <img src="assets/img/logo/logo-black.png" alt="logo" />
                    </a>
                  </div>
                </div>
                <div className="tp-main-menu-mobile fix d-xl-none mb-40" />
                <div className="offcanvas__contact">
                  <h4 className="offcanvas__title">Contacts</h4>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-sharp fa-solid fa-location-dot" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="https://www.google.com/maps/search/86+Road+Broklyn+Street,+600+New+York,+USA/@40.6897806,-74.0278086,12z/data=!3m1!4b1">
                        86 Road Broklyn Street, 600{" "}
                      </a>
                    </div>
                  </div>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-solid fa-envelope" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="https://template.wphix.com/cdn-cgi/l/email-protection#bfd1dadadbd7dad3cfffdcd0d2cfded1c691dcd0d2">
                        {" "}
                        
                        info@irembofinance.com
                      </a>
                    </div>
                  </div>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-solid fa-phone" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="tel:+256 (0) 772 415 497">
                        {" "}
                        +256 (0) 772 415 497
                      </a>
                    </div>
                  </div>
                </div>
                <div className="offcanvas__social">
                  <a className="icon facebook" href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a className="icon twitter" href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a className="icon youtube" href="#">
                    <i className="fab fa-youtube" />
                  </a>
                  <a className="icon linkedin" href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="body-overlay" />
          {/* ...............................................
          HEADER
          ............................................... */}
          <AppHeader />
          <main>


          <section className="tp-hero-area-4 pt-30 pb-30  p-relative" data-bg-color="#16243E" style={{backgroundColor: 'rgb(22, 36, 62)'}}>
            <div className="breadcrumb__bg" data-background="assets/img/breadcrumb/bg.png" style={{backgroundImage: 'url("assets/img/breadcrumb/bg.png")'}} />
            <div className="container">
                <div className="row align-items-center">
                <div className="col-sm-6">
                    <div className="breadcrumb__content">
                    <h3 className="breadcrumb__title">Contact</h3>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="breadcrumb__content">
                    <div className="breadcrumb__list text-center text-sm-end">
                        <span><Link to="/home">Home</Link></span>
                        <span className="dvdr"><i className="fa-regular fa-angle-right" /></span>
                        <span>Contact</span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>

            
            <section className="tp-contact-breadcrumb-area pt-120 pb-120">
        <div className="container">
          <div className="tp-contact-breadcrumb-box">
            <div className="tp-contact-breadcrumb-social">
              <a href="#">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="#">
                <i className="fab fa-twitter" />
              </a>
              <a href="#">
                <i className="fa-brands fa-instagram" />
              </a>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <div className="tp-contact-breadcrumb-content">
                  <h3
                    
                    className="tp-contact-breadcrumb-title"
                  >
                    Get in touch
                  </h3>
                  <p>
                    We are here for you! how can we help, We are here for you!{" "}
                  </p>
                  <form
                    id="contact-form"
                    action="https://template.wphix.com/finbest-prv/finbest/assets/mail.php"
                    method="POST"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="tp-contact-input">
                          <input
                            name="name"
                            type="text"
                            placeholder="Your Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="tp-contact-input">
                          <input
                            name="email"
                            type="email"
                            placeholder="Email Here"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="tp-contact-input">
                          <textarea
                            name="message"
                            placeholder="Message Here"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="tp-contact-breadcrumb-btn">
                          <button type="submit" className="tp-btn">
                            SUBMIT
                          </button>
                          <p className="ajax-response" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="tp-contact-breadcrumb-wrapper">
                  <div className="tp-contact-breadcrumb-item mb-40 d-flex">
                    <div className="tp-contact-breadcrumb-item-icon">
                      <span>
                        <i className="fa-solid fa-location-dot" />
                      </span>
                    </div>
                    <div className="tp-contact-breadcrumb-item-content">
                      <h3 className="tp-contact-breadcrumb-item-title">
                        Address
                      </h3>
                      <a href="https://www.google.com/maps" target="_blank">
                        Block 16 ,Msafiri Drive, Kitebero Cell - Mbarara City
                      </a>
                    </div>
                  </div>
                  <div className="tp-contact-breadcrumb-item mb-40 d-flex">
                    <div className="tp-contact-breadcrumb-item-icon">
                      <span>
                        <i className="fa-solid fa-envelope" />
                      </span>
                    </div>
                    <div className="tp-contact-breadcrumb-item-content">
                      <h3 className="tp-contact-breadcrumb-item-title">
                        Mail Us
                      </h3>

                      <a href="mail:tanya.hill@example.com" target="_blank">
                        <span
                          className="__cf_email__"
                          data-cfemail="790a180b18571a0b0c03391c01181409151c571a1614"
                        >
                          info@irembofinance.com
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="tp-contact-breadcrumb-item d-flex">
                    <div className="tp-contact-breadcrumb-item-icon">
                      <span>
                        <i className="fa-solid fa-phone" />
                      </span>
                    </div>
                    <div className="tp-contact-breadcrumb-item-content">
                      <h3 className="tp-contact-breadcrumb-item-title">
                        Telephone
                      </h3>
                      <a href="#">+256 772 415 497</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

            

            
          </main>
          <Footer />
        </div>
      )}
    </div>
    </> 
    );
}
 
export default Contact;