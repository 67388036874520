import StickyHeader from "./StickyHeader";
// import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
const AppHeader = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <header className="tp-header-area-3 p-relative">
        <div className="tp-header-box-3">
          <div className="tp-header-logo-3 d-none d-xl-block">
            <a to="/home">
              <img src="assets/img/logo/logo.png" alt />
            </a>
          </div>
          <div className="tp-header-box-main-3">
            <div className="tp-header-top-3 d-none d-xl-block">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-xxl-7 col-xl-9">
                    <div className="tp-header-top-info-3">
                      <ul>
                        <li>
                          <a to="https://template.wphix.com/cdn-cgi/l/email-protection#2841464e47684d50494558444d064b4745">
                            <span>
                              <i className="fa-solid fa-envelope" />
                            </span>
                            info@irembofinance.com
                          </a>
                        </li>
                        <li>
                          <a
                            to="https://www.google.com/maps/search/6391+Elgin+St,+Wilmington,+DE,+USA/@39.7298967,-75.5645038,13z/data=!3m1!4b1"
                            target="_blank"
                          >
                            <span>
                              <i className="fa-sharp fa-solid fa-location-dot" />
                            </span>
                            +256 772 415 497
                          </a>
                        </li>
                        <li>
                          <a to="#">
                            <span>
                              <i className="fa-solid fa-clock" />
                            </span>
                            Sunday - Friday:<b>9 am - 8 pm</b>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xxl-5 col-xl-3">
                    <div className="tp-header-top-right-3 d-flex justify-content-end align-items-center">
                      <div className="tp-header-top-social-3">
                        <a to="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                        <a to="#">
                          <i className="fab fa-twitter" />
                        </a>
                        <a to="#">
                          <i className="fa-brands fa-instagram" />
                        </a>
                        <a to="#">
                          <i className="fa-brands fa-linkedin" />
                        </a>
                      </div>
                      <div className="tp-header-top-support d-xxl-flex d-none">
                        <a to="#">Help /</a>
                        <a to="#">Support /</a>
                        <a to="#">contact</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tp-header-wrapper-3">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-xxl-6 col-xl-7 col-6">
                    <div className="tp-main-menu home-3 align-items-center justify-content-between d-flex">
                      <div className="tp-main-menu-logo d-block d-xl-none">
                        <a to="index.html">
                          <img src="assets/img/logo/logo-black.png" alt />
                        </a>
                      </div>
                      <div className="d-none d-xl-flex">
                        <nav className="tp-main-menu-content">
                          <ul>
                            <li>
                              <Link to="/home">Home</Link>
                            </li>
                            <li>
                              <Link to="/about-us">About</Link>
                            </li>
                            <li>
                              <Link to="/our-team">Team</Link>
                            </li>
                            <li>
                              <Link to="/payment-plan">Pricing</Link>
                            </li>
                            <li>
                              <Link to="/clients">Portifolio</Link>
                            </li>
                            <li>
                            <Link to="/contact">Contact</Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-5 col-6">
                    <div className="tp-header-main-right-3 d-flex align-items-center justify-content-xl-end">
                      {/* <div className="tp-header-contact d-xl-flex d-none align-items-center">
                        <div className="tp-header-contact-inner-3 d-xxl-flex align-items-center d-none">
                          <div className="tp-header-icon-3">
                            <span>
                              <i className="fa-solid fa-phone" />
                            </span>
                          </div>
                          <div className="tp-header-contact-content">
                            <p>Contact Us</p>
                            <span>
                              <a to="tel:555-0111">+256 772 415 497</a>
                            </span>
                          </div>
                        </div>
                        <div className="tp-header-contact-search-3 search-open-btn">
                          <span>
                            <i className="fa-solid fa-magnifying-glass" />
                          </span>
                        </div>
                        <div className="tp-header-btn-3">
                          <a className="tp-btn" to="contact.html">
                            Get Started
                          </a>
                        </div>
                      </div>
                      <div className="tp-header-main-right-hamburger-btn d-xl-none offcanvas-open-btn text-end">
                        <button className="hamburger-btn">
                          <span />
                          <span />
                          <span />
                        </button>
                      </div> */}

                      <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header>
                          <div className="offcanvas__top mb-50 d-flex justify-content-between align-items-center">
                            <div className="offcanvas__logo logo">
                              <a to="index.html">
                                <img
                                  src="assets/img/logo/logo-black.png"
                                  alt="logo"
                                />
                              </a>
                            </div>
                          </div>
                          <Offcanvas.Title>
                            <div className="offcanvas__close-btn-wrapper">
                              <button
                                onClick={handleClose}
                                className="align-items-end offcanvas__close-btn offcanvas-close-btn"
                              >
                                <svg
                                  width={12}
                                  height={12}
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11 1L1 11"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M1 1L11 11"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div className="tp-main-menu-mobile fix d-xl-none mb-40">
                            <nav className="tp-main-menu-content">
                              <ul>
                                <li>
                                  <Link to="/home">Home</Link>
                                </li>
                                <li>
                                  <Link to="/about-us">About</Link>
                                </li>
                                <li>
                                  <Link to="/our-team">Team</Link>
                                </li>
                                <li>
                                  <Link to="/payment-plan">Pricing</Link>
                                </li>
                                <li>
                                  <Link to="/clients">Portifolio</Link>
                                </li>
                                <li>
                                <Link to="/contact">Contact</Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                      <div className="tp-header-main-right-hamburger-btn d-xl-none offcanvas-open-btn text-end">
                        <button onClick={handleShow} className="hamburger-btn">
                          <span />
                          <span />
                          <span />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <StickyHeader /> */}
      <header id="header-sticky" className="tp-header-main-sticky p-relative">
        <div className="tp-header-space-2">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-xl-3 col-6">
                <div className="tp-header-logo-2 p-relative">
                  <a to="index.html">
                    <img src="assets/img/logo/logo-black.png" alt />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 d-none d-xl-block">
                <div className="tp-main-menu home-2 d-none d-xl-block">
                  <nav className="tp-main-menu-content">
                    <ul>
                      <li>
                        <Link to="/home">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About</Link>
                      </li>
                      <li>
                        <Link to="/our-team">Team</Link>
                      </li>
                      <li>
                        <Link to="/payment-plan">Pricing</Link>
                      </li>
                      <li>
                        <Link to="/clients">Portifolio</Link>
                      </li>
                      <li>
                      <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-xl-3 col-6">
                <div className="tp-header-main-right-2 d-flex align-items-center justify-content-xl-end">
                  <div className="tp-header-contact-2 d-flex align-items-center">
                    <div className="tp-header-contact-search search-open-btn d-none d-xxl-block">
                      <span>
                        <i className="fa-solid fa-magnifying-glass" />
                      </span>
                    </div>
                    <div className="tp-header-contact-inner d-none d-xl-flex align-items-center">
                      <div className="tp-header-contact-icon">
                        <span>
                          <i className="fa-solid fa-phone" />
                        </span>
                      </div>
                      <div className="tp-header-contact-content">
                        <p>Contact Us</p>
                        <span>
                          <a to="tel:555-0111">+256 772 415 497</a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="tp-header-main-right-hamburger-btn d-xl-none offcanvas-open-btn text-end">
                    <button onClick={handleShow} className="hamburger-btn">
                      <span />
                      <span />
                      <span />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default AppHeader;
