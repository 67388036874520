import Statistics from "../components/Statistics";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import { useEffect, useState } from "react";
import BackToTopButton from "../components/BackToTopButton";
import AppHeader from "../components/AppHeader";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
const Policy = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [counterOn, setCounterOn] = useState(false);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000); // Simulate a 2-second loading delay
      return () => clearTimeout(timer);
    }, []);
    return ( 
    <>
    <div>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <BackToTopButton />
          <div className="search-area">
            <div className="search-inner p-relative">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="search-wrapper">
                      <div className="search-close">
                        <button className="search-close-btn">
                          <svg
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 1L1 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M1 1L11 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="search-content pt-35">
                        <h5 className="heading text-center mb-30">
                          Hi! How can we help You?
                        </h5>
                        <div className="d-flex justify-content-center px-5">
                          <div className="search w-100 p-relative">
                            <input
                              type="text"
                              className="search-input"
                              placeholder="Search..."
                            />
                            <button className="search-icon">
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-overlay" />
          <div className="offcanvas__area">
            <div className="offcanvas__wrapper">
              <div className="offcanvas__close">
                <button className="offcanvas__close-btn offcanvas-close-btn">
                  <svg
                    width={12}
                    height={12}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1L1 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1L11 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="offcanvas__content">
                <div className="offcanvas__top mb-50 d-flex justify-content-between align-items-center">
                  <div className="offcanvas__logo logo">
                    <a href="/">
                      <img src="assets/img/logo/logo-black.png" alt="logo" />
                    </a>
                  </div>
                </div>
                <div className="tp-main-menu-mobile fix d-xl-none mb-40" />
                <div className="offcanvas__contact">
                  <h5 className="offcanvas__title">Policy</h5>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-sharp fa-solid fa-location-dot" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="https://www.google.com/maps/search/86+Road+Broklyn+Street,+600+New+York,+USA/@40.6897806,-74.0278086,12z/data=!3m1!4b1">
                        86 Road Broklyn Street, 600{" "}
                      </a>
                    </div>
                  </div>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-solid fa-envelope" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="https://template.wphix.com/cdn-cgi/l/email-protection#bfd1dadadbd7dad3cfffdcd0d2cfded1c691dcd0d2">
                        {" "}
                        
                        info@irembofinance.com
                      </a>
                    </div>
                  </div>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-solid fa-phone" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="tel:+256 (0) 772 415 497">
                        {" "}
                        +256 (0) 772 415 497
                      </a>
                    </div>
                  </div>
                </div>
                <div className="offcanvas__social">
                  <a className="icon facebook" href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a className="icon twitter" href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a className="icon youtube" href="#">
                    <i className="fab fa-youtube" />
                  </a>
                  <a className="icon linkedin" href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="body-overlay" />
          {/* ...............................................
          HEADER
          ............................................... */}
          <AppHeader />
          <main>


          <section className="tp-hero-area-4 pt-30 pb-30  p-relative" data-bg-color="#16243E" style={{backgroundColor: 'rgb(22, 36, 62)'}}>
            <div className="breadcrumb__bg" data-background="assets/img/breadcrumb/bg.png" style={{backgroundImage: 'url("assets/img/breadcrumb/bg.png")'}} />
            <div className="container">
                <div className="row align-items-center">
                <div className="col-sm-6">
                    <div className="breadcrumb__content">
                    <h5 className="breadcrumb__title">Policy</h5>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="breadcrumb__content">
                    <div className="breadcrumb__list text-center text-sm-end">
                        <span><Link to="/home">Home</Link></span>
                        <span className="dvdr"><i className="fa-regular fa-angle-right" /></span>
                        <span>Policy</span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>

            
            <section
              className="tp-service-area-3 pt-60 pb-60"
              data-bg-color="#F6F6F9"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tp-service-title-wrapper-3 mb-40">
                      <span className="text-center tp-section-title-pre">Privacy Policy</span>
                    <div className="card">
                        <div className="card-body p-5">
                           <div>
  <p><strong>Effective Date: 22 July, 2024</strong></p>
  <hr></hr>
  <h5>1. Introduction</h5>
  <p>Ahuriire Uganda Limited ("we," "our," or "us") is committed to protecting the privacy of our users ("you" or "your"). This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use the iRembo Finance Management Information System and iRembo Agent App ("collectively, the Application"). Please read this Privacy Policy carefully. By using the Application, you agree to the terms of this Privacy Policy.</p>
  <h5>2. Information We Collect</h5>
  <p>We collect various types of information to provide and improve our services. This information can be categorized into the following:</p>
  <h5>a. Personal Information</h5>
  <ul>
    <li><strong>Name:</strong> To identify and communicate with you.</li>
    <li><strong>Email Address:</strong> To send notifications, updates, and support.</li>
    <li><strong>Phone Number:</strong> For account verification and customer support.</li>
    <li><strong>Date of Birth:</strong> To verify identity and provide age-appropriate services.</li>
    <li><strong>Identification Numbers:</strong> Such as national ID for verification purposes.</li>
  </ul>
  <h5>b. Financial Information</h5>
  <ul>
    <li><strong>Account Numbers:</strong> For transaction processing.</li>
    <li><strong>Transaction History:</strong> To provide detailed account statements and reports.</li>
    <li><strong>Loan Details:</strong> To manage and track loan applications and repayments.</li>
  </ul>
  <h5>c. Technical Information</h5>
  <ul>
    <li><strong>Operating System:</strong> To ensure compatibility and provide technical support.</li>
    <li><strong>Browser Type:</strong> To optimize our services for different browsers.</li>
  </ul>
  <h5>3. How We Use Your Information</h5>
  <p>We use the information we collect for various purposes, including:</p>
  <ul>
    <li><strong>Providing and Maintaining Services:</strong> To operate and deliver our services effectively.</li>
    <li><strong>Processing Transactions:</strong> To handle deposits, withdrawals, transfers, and other financial activities.</li>
    <li><strong>Identity Verification:</strong> To confirm your identity and prevent unauthorized access.</li>
    <li><strong>Customer Support:</strong> To assist you with inquiries and issues.</li>
    <li><strong>Service Improvement:</strong> To analyze usage patterns and enhance our services.</li>
    <li><strong>Legal Compliance:</strong> To fulfill legal and regulatory requirements.</li>
  </ul>
  <h5>4. How We Share Your Information</h5>
  <p>We respect your privacy and do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:</p>
  <h5>a. Service Providers</h5>
  <p>We engage third-party companies to perform services on our behalf, such as payment processing and customer support. These service providers have access to your personal information only to perform specific tasks and are obligated to maintain its confidentiality.</p>
  <h5>b. Legal and Regulatory Authorities</h5>
  <p>We may disclose your information to comply with legal obligations, such as responding to subpoenas, court orders, or other legal processes, or to protect our rights and interests.</p>
  <h5>c. Business Transfers</h5>
  <p>In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner. We will notify you before your personal information becomes subject to a different privacy policy.</p>
  <h5>5. Data Security</h5>
  <p>We implement robust security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. These measures include:</p>
  <ul>
    <li><strong>Encryption:</strong> We use encryption to protect sensitive data during transmission.</li>
    <li><strong>Access Controls:</strong> Only authorized personnel have access to your personal information.</li>
    <li><strong>Regular Security Audits:</strong> We conduct regular security audits to identify and address potential vulnerabilities.</li>
    <li><strong>Secure Storage:</strong> Personal information is stored securely, both physically and electronically.</li>
  </ul>
  <p>However, no security system is infallible, and we cannot guarantee the absolute security of your information.</p>
  <h5>6. Your Rights</h5>
  <p>You have certain rights regarding your personal information. These include:</p>
  <h5>a. Access</h5>
  <p>You can request a copy of the information we hold about you.</p>
  <h5>b. Correction</h5>
  <p>You can request correction of inaccurate or incomplete information.</p>
  <h5>c. Deletion</h5>
  <p>You can request deletion of your personal information, subject to legal obligations.</p>
  <h5>d. Objection</h5>
  <p>You can object to the processing of your information for certain purposes.</p>
  <h5>e. Withdrawal of Consent</h5>
  <p>You can withdraw your consent for processing your information, where applicable.</p>
  <p>To exercise these rights, please contact us.</p>
  <h5>7. Changes to This Privacy Policy</h5>
  <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any changes by posting the new Privacy Policy on our application. Your continued use of our services after any changes indicates your acceptance of the updated Privacy Policy.</p>
  <h5>8. Contacting Us</h5>
  <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
  <p>Email: <a href="mailto:ahuriireltd@gmail.com">ahuriireltd@gmail.com</a></p>
  <p>Phone: 0779586330</p>
  <p>Address: Block 16, Msafiri Drive, Mbarara, Uganda</p>
  <hr />
  <p>This Privacy Policy is intended to provide transparency and ensure that you understand our practices regarding your personal information. Thank you for trusting us with your data.</p>
</div>

                        </div>
                    </div>
                     
                    </div>
                  </div>
                </div>

              </div>
            </section>

        
          </main>
          <Footer />
        </div>
      )}
    </div>
    </> 
    );
}
 
export default Policy;