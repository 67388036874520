const Loader = () => {
  return (
    <>
      {/* <div className="loader-wrapper">
        <div className="loader"></div>
      </div> */}

      <div id="loading">
        <div id="loading-center">
          <div className="preloader" />
        </div>
      </div>
    </>
  );
};

export default Loader;
