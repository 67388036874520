import Statistics from "../components/Statistics";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import { useEffect, useState } from "react";
import BackToTopButton from "../components/BackToTopButton";
import AppHeader from "../components/AppHeader";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
const Team = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [counterOn, setCounterOn] = useState(false);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000); // Simulate a 2-second loading delay
      return () => clearTimeout(timer);
    }, []);
    return ( 
    <>
    <div>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <BackToTopButton />
          <div className="search-area">
            <div className="search-inner p-relative">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="search-wrapper">
                      <div className="search-close">
                        <button className="search-close-btn">
                          <svg
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 1L1 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M1 1L11 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="search-content pt-35">
                        <h3 className="heading text-center mb-30">
                          Hi! How can we help You?
                        </h3>
                        <div className="d-flex justify-content-center px-5">
                          <div className="search w-100 p-relative">
                            <input
                              type="text"
                              className="search-input"
                              placeholder="Search..."
                            />
                            <button className="search-icon">
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-overlay" />
          <div className="offcanvas__area">
            <div className="offcanvas__wrapper">
              <div className="offcanvas__close">
                <button className="offcanvas__close-btn offcanvas-close-btn">
                  <svg
                    width={12}
                    height={12}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1L1 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1L11 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="offcanvas__content">
                <div className="offcanvas__top mb-50 d-flex justify-content-between align-items-center">
                  <div className="offcanvas__logo logo">
                    <a href="/">
                      <img src="assets/img/logo/logo-black.png" alt="logo" />
                    </a>
                  </div>
                </div>
                <div className="tp-main-menu-mobile fix d-xl-none mb-40" />
                <div className="offcanvas__contact">
                  <h4 className="offcanvas__title">Contacts</h4>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-sharp fa-solid fa-location-dot" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="https://www.google.com/maps/search/86+Road+Broklyn+Street,+600+New+York,+USA/@40.6897806,-74.0278086,12z/data=!3m1!4b1">
                        86 Road Broklyn Street, 600{" "}
                      </a>
                    </div>
                  </div>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-solid fa-envelope" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="https://template.wphix.com/cdn-cgi/l/email-protection#bfd1dadadbd7dad3cfffdcd0d2cfded1c691dcd0d2">
                        {" "}
                        
                        info@irembofinance.com
                      </a>
                    </div>
                  </div>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-solid fa-phone" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="tel:+256 (0) 772 415 497">
                        {" "}
                        +256 (0) 772 415 497
                      </a>
                    </div>
                  </div>
                </div>
                <div className="offcanvas__social">
                  <a className="icon facebook" href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a className="icon twitter" href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a className="icon youtube" href="#">
                    <i className="fab fa-youtube" />
                  </a>
                  <a className="icon linkedin" href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="body-overlay" />
          {/* ...............................................
          HEADER
          ............................................... */}
          <AppHeader />
          <main>


          <section className="tp-hero-area-4 pt-30 pb-30  p-relative" data-bg-color="#16243E" style={{backgroundColor: 'rgb(22, 36, 62)'}}>
            <div className="breadcrumb__bg" data-background="assets/img/breadcrumb/bg.png" style={{backgroundImage: 'url("assets/img/breadcrumb/bg.png")'}} />
            <div className="container">
                <div className="row align-items-center">
                <div className="col-sm-6">
                    <div className="breadcrumb__content">
                    <h3 className="breadcrumb__title">Team</h3>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="breadcrumb__content">
                    <div className="breadcrumb__list text-center text-sm-end">
                        <span><Link to="/home">Home</Link></span>
                        <span className="dvdr"><i className="fa-regular fa-angle-right" /></span>
                        <span>Team</span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>

                <section className="tp-team-breadcrumb-area pt-120 pb-70">
        <div className="container">
        <span className=" text-center tp-section-title-pre">Meet Our Team</span>
<br/>
<br/>
<br/>
            <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/fr.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">Fr. Emmanuel Tusiime</a></h4>
                    <p>Chairman & CEO</p>
                </div>
                </div>
            </div>
            {/* <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/fr.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">Kathryn Murphy</a></h4>
                    <p>Designer</p>
                </div>
                </div>
            </div> */}
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".7s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.7s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/dave.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">David Bwambale</a></h4>
                    <p> Tech Executive Officer</p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.9s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/kenneth.jpg" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">Atukwatse Kenneth</a></h4>
                    <p>Legal Advisor</p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/essy.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">Esther Abaasa</a></h4>
                    <p>Secretary</p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/kamya.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">Edward Kamya</a></h4>
                    <p>Member</p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".7s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.7s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/flora.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">Florence Atukwatse</a></h4>
                    <p>Accountant</p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.9s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/audrey.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">Esther Ainomugasho</a></h4>
                    <p>Sales Executive</p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.9s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/steve.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">  Aijuka Steven</a></h4>
                    <p>Sales Executive</p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.9s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/dora.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">  Aijuka Steven</a></h4>
                    <p>Sales Executive</p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.9s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/shiva.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">  Shivan Amutuhaire</a></h4>
                    <p>Accounts Assistant</p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.9s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/bigs.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">Bigega Innocent</a></h4>
                    <p>Lead Software Developer</p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.9s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/inno.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">Karuhanga Innocent</a></h4>
                    <p>Software Developer</p>
                </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.9s', animationName: 'fadeInUp'}}>
                <div className="tp-team-item-thumb">
                    <a href="team-details.html"><img src="assets/img/team/gero.png" alt /></a>
                </div>
                <div className="tp-team-social">
                    <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f" /></a>
                    <a className="icon-2" href="#"><i className="fa-brands fa-twitter" /></a>
                    <a className="icon-3" href="#"><i className="fa-brands fa-instagram" /></a>
                    <a className="icon-4" href="#"><i className="fa-brands fa-pinterest" /></a>
                </div>
                <div className="tp-team-info text-center">
                    <h4 className="tp-team-info-title"><a href="team-details.html">  Hyuha Gerald</a></h4>
                    <p>Software Developer</p>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>


            

            
          </main>
          <Footer />
        </div>
      )}
    </div>
    </> 
    );
}
 
export default Team;