import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer
        className="tp-footer-area pt-80 p-relative z-index-1"
       
        // style={{ backgroundColor: "linear-gradient(90deg, #53860d 0%, #73b41a 100%);" }}
      >
        <div className="tp-footer-bg-shape">
          <img src="assets/img/footer/bg-shape.png" alt />
        </div>
        <div className="tp-footer-main-area tp-footer-border">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="tp-footer-widget tp-footer-col-1 mb-50">
                  <div className="tp-footer-logo mb-20">
                    <a href="/">
                      {" "}
                      <img src="assets/img/logo/logo.png" alt />
                    </a>
                  </div>
                  <div className="tp-footer-widget-content">
                    <p>
                      Payment processing is a critical component of any business
                      that sells goods or se Payment processing is a critical
                      component of any.
                    </p>
                    <div className="tp-footer-widget-social">
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-instagram" />
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-pinterest" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="tp-footer-widget tp-footer-col-2 mb-50">
                  <h3 className="tp-footer-widget-title">Quick links</h3>
                  <div className="tp-footer-widget-content">
                    <ul>
                      <li>
                        <Link to="/home">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About</Link>
                      </li>
                      <li>
                        <Link to="/our-team">Team</Link>
                      </li>
                      <li>
                        <Link to="/payment-plan">Pricing</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="tp-footer-widget tp-footer-col-4 mb-50">
                  <h3 className="tp-footer-widget-title">Contact us</h3>
                  <div className="tp-footer-widget-content">
                    <div  className="tp-footer-widget-contact">
                      <div className="tp-footer-widget-contact-inner">
                        <a href="https://www.google.com/maps" target="_blank">
                          <i className="fa-sharp fa-solid fa-location-dot" />{" "}
                          Block 16 Msafiri Drive <br></br> Mbarara City - Uganda{" "}
                        </a>
                      </div>
                      <div className="tp-footer-widget-contact-inner">
                        <a href="tel:+256 (0) 772 415 497">
                          <i className="fa-solid fa-phone" />
                          +256 (0) 772 415 497
                        </a>
                      </div>
                      <div className="tp-footer-widget-contact-inner">
                        <a href="https://template.wphix.com/cdn-cgi/l/email-protection#a1c8cfc7cee1c4d9c0ccd1cdc48fc2cecc">
                          <i className="fa-solid fa-envelope" />{" "}
                          <span
                            className="__cf_email__"
                            data-cfemail="71181f171e311409101c011d145f121e1c"
                          >
                            info@irembofinance.com
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-footer-copyright-area p-relative">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="text-center tp-footer-copyright-inner">
                  <p>© Irembo Finance 2024 | All Rights Reserved</p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
