import Statistics from "../components/Statistics";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import { useEffect, useState } from "react";
import BackToTopButton from "../components/BackToTopButton";
import AppHeader from "../components/AppHeader";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
const Portfolio = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [counterOn, setCounterOn] = useState(false);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000); // Simulate a 2-second loading delay
      return () => clearTimeout(timer);
    }, []);
    return ( 
    <>
    <div>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <BackToTopButton />
          <div className="search-area">
            <div className="search-inner p-relative">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="search-wrapper">
                      <div className="search-close">
                        <button className="search-close-btn">
                          <svg
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 1L1 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M1 1L11 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="search-content pt-35">
                        <h3 className="heading text-center mb-30">
                          Hi! How can we help You?
                        </h3>
                        <div className="d-flex justify-content-center px-5">
                          <div className="search w-100 p-relative">
                            <input
                              type="text"
                              className="search-input"
                              placeholder="Search..."
                            />
                            <button className="search-icon">
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-overlay" />
          <div className="offcanvas__area">
            <div className="offcanvas__wrapper">
              <div className="offcanvas__close">
                <button className="offcanvas__close-btn offcanvas-close-btn">
                  <svg
                    width={12}
                    height={12}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1L1 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1L11 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="offcanvas__content">
                <div className="offcanvas__top mb-50 d-flex justify-content-between align-items-center">
                  <div className="offcanvas__logo logo">
                    <a href="/">
                      <img src="assets/img/logo/logo-black.png" alt="logo" />
                    </a>
                  </div>
                </div>
                <div className="tp-main-menu-mobile fix d-xl-none mb-40" />
                <div className="offcanvas__contact">
                  <h4 className="offcanvas__title">Portfolio</h4>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-sharp fa-solid fa-location-dot" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="https://www.google.com/maps/search/86+Road+Broklyn+Street,+600+New+York,+USA/@40.6897806,-74.0278086,12z/data=!3m1!4b1">
                        86 Road Broklyn Street, 600{" "}
                      </a>
                    </div>
                  </div>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-solid fa-envelope" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="https://template.wphix.com/cdn-cgi/l/email-protection#bfd1dadadbd7dad3cfffdcd0d2cfded1c691dcd0d2">
                        {" "}
                        
                        info@irembofinance.com
                      </a>
                    </div>
                  </div>
                  <div className="offcanvas__contact-content d-flex">
                    <div className="offcanvas__contact-content-icon">
                      <i className="fa-solid fa-phone" />
                    </div>
                    <div className="offcanvas__contact-content-content">
                      <a href="tel:+256 (0) 772 415 497">
                        {" "}
                        +256 (0) 772 415 497
                      </a>
                    </div>
                  </div>
                </div>
                <div className="offcanvas__social">
                  <a className="icon facebook" href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a className="icon twitter" href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a className="icon youtube" href="#">
                    <i className="fab fa-youtube" />
                  </a>
                  <a className="icon linkedin" href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="body-overlay" />
          {/* ...............................................
          HEADER
          ............................................... */}
          <AppHeader />
          <main>


          <section className="tp-hero-area-4 pt-30 pb-30  p-relative" data-bg-color="#16243E" style={{backgroundColor: 'rgb(22, 36, 62)'}}>
            <div className="breadcrumb__bg" data-background="assets/img/breadcrumb/bg.png" style={{backgroundImage: 'url("assets/img/breadcrumb/bg.png")'}} />
            <div className="container">
                <div className="row align-items-center">
                <div className="col-sm-6">
                    <div className="breadcrumb__content">
                    <h3 className="breadcrumb__title">Portfolio</h3>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="breadcrumb__content">
                    <div className="breadcrumb__list text-center text-sm-end">
                        <span><Link to="/home">Home</Link></span>
                        <span className="dvdr"><i className="fa-regular fa-angle-right" /></span>
                        <span>Portfolio</span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>

            
            <section
              className="tp-service-area-3 pt-60 pb-60"
              data-bg-color="#F6F6F9"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tp-service-title-wrapper-3 mb-40">
                      <span className="text-center tp-section-title-pre">Some Of Our Esteemed Clients</span>

                      <p> We take great pride in providing an advanced Finance Management Information System designed exclusively to meet the distinctive requirements 
          of Saving & Credit Cooperative Organizations (SACCOs) and Micro Finance Institutions (MFIs) operating in the East Africa Region. 
          Our comprehensive solution offers cutting-edge features and functionalities tailored to streamline financial operations, automate processes, 
          and enhance overall efficiency. With a deep understanding of the challenges faced by SACCOs and MFIs, we have developed a robust platform that 
          empowers our clients to manage their financial activities seamlessly. Our commitment to delivering tailored solutions and unwavering support has
          made us the trusted partner of choice for numerous SACCOs and MFIs in the region. We continue to innovate and evolve our system to stay ahead of the 
          dynamic industry landscape, ensuring our esteemed clients receive the most advanced tools to thrive and succeed in their operations.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/kgmt.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Kakoba Merchandise and Traders SACCO
                        <br/>
                        High Street Mbarara
                        </p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/isingiroagro.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Isingiro Agro-Entrepreneurs SACCO LTD
                        <br/>
                        Kaberebere, Isingiro
                        </p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/isingironorth.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Isingiro North Tailors Co-operative                        
                        <br/>
                        Kabingo, Isingiro
                        </p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/kakoba.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Kakoba Nyamityobora Farmers' SACCO <br/>
                        High Street, Mbarara
                        </p>
                    </div>
                  </div>
                  
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/kamwenge.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Kamwenge Bus Operators Cooperstive Society <br/>
                        Kamwenge Bus Park
                        </p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/karama.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Karama Ahurrire SACCO <br/>
                        Karama, TC                        
                        </p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/keikara.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Keikara Rugando SACCO <br/>
                        Rugando, Kabale Road</p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/agape.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Agape Youth SACCO <br/>
                        Nyamitanga, Mbarara
                        </p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/komon.jpg" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Capital and credit financial services <br/>
                        Tataitwe - Mbarara City
                        </p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/munasu.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Mukama Natukunda SACCO<br/>
                        Mackansigh Street Mbarara City
                        </p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/kaharo.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Kaharo Tukore SACCO<br/>
                        Kaharo 17 Miles, Isingiro Road
                        </p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/ibanda.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Ibanda South Restuarant Owner's SACCO<br/>
                        Rwomuhoro, Ibanda Municipality
                        </p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="150"  src="assets/img/team/mef.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Mbarara Enterprising Famer's SACCO<br/>
                        Koranorya, Mbarara City
                        </p>
                    </div>
                  </div>
                  <div className="mb-5 col-lg-3 col-md-6">
                    <div className="bg-white p-5  text-center rounded-lg shadow">
                    <div>
                    <div className="team-img-wrapper">
                        <img width="170"  src="assets/img/team/mre.png" />
                    </div>
                    </div>
                    </div>
                    <div className="mb-3 mt-3 text-center">
                        <p style={{fontWeight:"bold"}}>
                        Mbarara Responsibilities (MRE) SACCO<br/>
                        Katete, Mbarara City
                        </p>
                    </div>
                  </div>

                </div>
              </div>
            </section>

            

            
          </main>
          <Footer />
        </div>
      )}
    </div>
    </> 
    );
}
 
export default Portfolio;